.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.youtubeiframe{
  height: 180px !important;
  width: 320px !important;
}

.new-text {
  animation: new-item 5s ease-out;
  background-color: #F5F5DC;
}

@keyframes new-item {
  0% {
    transform: scale(1);
    background-color: #f7f7f7;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
  50% {
    transform: scale(1.1);
    background-color: #fff;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
  }
  100% {
    transform: scale(1);
    background-color: #f7f7f7;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
}

.fairly-used {
  animation: fairly-used 3s ease-in-out;
}

@keyframes fairly-used {
  0% {
    transform: rotate(0deg);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  30% {
    transform: rotate(5deg);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  60% {
    transform: rotate(-5deg);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
  100% {
    transform: rotate(0deg);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}